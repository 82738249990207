import React, { FC, ReactNode } from 'react';
import { joinStrings } from '../../utils/string';
import './Section.scss';

interface Props {
  className?: string;
  children: ReactNode;
}

export const Section: FC<Props> = ({ className, children }) => {
  return (
    <main className={joinStrings(['content-section', className])}>
      <div className="content-section__children">{children}</div>
    </main>
  );
};
