import React, { FC } from 'react';

export const LinkedinIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50.999" viewBox="0 0 51 50.999">
    <g id="social-linkedin" transform="translate(0.5 0.5)">
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M-93,47a24.845,24.845,0,0,1-9.731-1.965,24.916,24.916,0,0,1-7.947-5.358,24.916,24.916,0,0,1-5.358-7.947A24.845,24.845,0,0,1-118,22a24.843,24.843,0,0,1,1.965-9.731,24.915,24.915,0,0,1,5.358-7.946,24.916,24.916,0,0,1,7.947-5.358A24.845,24.845,0,0,1-93-3a24.843,24.843,0,0,1,9.731,1.965,24.916,24.916,0,0,1,7.946,5.358,24.916,24.916,0,0,1,5.358,7.946A24.843,24.843,0,0,1-68,22a24.845,24.845,0,0,1-1.965,9.731,24.916,24.916,0,0,1-5.358,7.947,24.915,24.915,0,0,1-7.946,5.358A24.843,24.843,0,0,1-93,47Zm4.025-24.707a2.686,2.686,0,0,1,2.683,2.683v7.513a1.075,1.075,0,0,0,1.074,1.074H-82a1.074,1.074,0,0,0,1.073-1.074V23.366a6.533,6.533,0,0,0-1.84-4.83,5.785,5.785,0,0,0-4.063-1.61,6.11,6.11,0,0,0-4.83,2.3v-.687a1.074,1.074,0,0,0-1.073-1.074h-3.22a1.074,1.074,0,0,0-1.073,1.074V32.489a1.074,1.074,0,0,0,1.073,1.074h3.22a1.074,1.074,0,0,0,1.073-1.074V24.976A2.686,2.686,0,0,1-88.975,22.293Zm-15.323-4.83a.24.24,0,0,0-.239.24v15.62a.24.24,0,0,0,.239.24h4.887a.24.24,0,0,0,.239-.24V17.7a.24.24,0,0,0-.239-.24Zm2.443-8.586a3.223,3.223,0,0,0-3.22,3.219,3.224,3.224,0,0,0,3.22,3.22,3.224,3.224,0,0,0,3.22-3.22A3.223,3.223,0,0,0-101.855,8.877Z"
        transform="translate(118 3)"
        fill="#ffc210"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);
