import { useState, useEffect } from 'react';

interface ReturnValue {
  shouldRender: boolean;
  shouldAnimate: boolean;
}

export const useDelayedMount = (
  isVisible: boolean,
  renderDelay = 1,
  animationDelay = 1,
  exitDelay = 500
): ReturnValue => {
  const [shouldRender, setShouldRender] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    let renderTimerId: string | number | NodeJS.Timeout | undefined,
      animationTimerId: string | number | NodeJS.Timeout | undefined,
      exitTimerId: string | number | NodeJS.Timeout | undefined;

    if (isVisible) {
      renderTimerId = setTimeout(() => {
        setShouldRender(true);
      }, renderDelay);
      animationTimerId = setTimeout(() => {
        setShouldAnimate(true);
      }, renderDelay + animationDelay);
    } else {
      setShouldAnimate(false); // Trigger exit animation
      exitTimerId = setTimeout(() => {
        setShouldRender(false);
      }, exitDelay);
    }

    return () => {
      clearTimeout(renderTimerId);
      clearTimeout(animationTimerId);
      clearTimeout(exitTimerId);
    };
  }, [isVisible, renderDelay, animationDelay, exitDelay]);

  return { shouldRender, shouldAnimate };
};

export default useDelayedMount;
