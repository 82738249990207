import { FC, ReactNode } from 'react';
import './SectionWithImages.scss';
import BgImg from '../../assets/menu-bg.png';
import { joinStrings } from '../../utils/string';

interface Props {
  bgUrl?: string;
  className?: string;
  children: ReactNode;
}

export const SectionWithImages: FC<Props> = ({ bgUrl, className, children }) => {
  return (
    <main className={joinStrings(['content-section-with-images', className])}>
      <img src={BgImg} alt="background" className="background top" />
      <div className="content-section-with-images__children">{children}</div>
      <img src={BgImg} alt="background" className="background bottom" />
    </main>
  );
};
