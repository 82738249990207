import { FC } from 'react';
// import BgImg from '../../../../assets/menu-bg-dark.png';
import BgImg from '../../../../assets/sun-bg.png';
import { IconText } from '../../../../components/shared/IconText';
import BrandGame from '../../../../assets/SVG/services-ba.svg';
import GameDev from '../../../../assets/SVG/services-gd.svg';
import Gamification from '../../../../assets/SVG/services-gm.svg';
import Gaming from '../../../../assets/SVG/services-gr.svg';
import Metaverse from '../../../../assets/SVG/services-md.svg';

import './WhoWeAre.scss';

export const WhoWeAre: FC = () => {
  return (
    <div className="who_container">
      <img src={BgImg} alt="background" className="who_container__background" />
      <div className="who_container__content">
        <div>
          <h2>Who We Are</h2>
          <h5>
            At <strong>Playnet Interactive</strong>, we blend several years of experience in branding and commercial
            product development with the cutting-edge skills of seasoned gaming professionals. This unique synergy
            enables us to craft immersive gaming experiences that are not only engaging but also strategically aligned
            with your business objectives. Discover how we can turn your vision into reality.
          </h5>
        </div>
        <div className="who_container__services">
          <IconText imgAlt="game-dev" imgSrc={GameDev} title="Game" description="Development" />
          <IconText imgAlt="brand-game" imgSrc={BrandGame} title="Brand" description="Advergaming" />
          <IconText imgAlt="gamification" imgSrc={Gamification} title="Gamification" description="Mechanics" />
          <IconText imgAlt="metaverse" imgSrc={Metaverse} title="Metaverse" description="Development" />
          <IconText imgAlt="gaming" imgSrc={Gaming} title="Gaming" description="Related" />
        </div>
      </div>
    </div>
  );
};
