import React, { FC } from 'react';
import { NavigationTemplate } from '../../components/NavigationTemplate';
import { Section } from '../../components/Section';
import { SectionWithImages } from '../../components/SectionWithImages';
import { GetInTouchButton } from '../../components/shared/GetInTouchButton';
import './About.scss';

export const About: FC = () => {
  return (
    <NavigationTemplate title="ABOUT" description="WHAT'S ON THE TABLE" className="about">
      <SectionWithImages className="about__content">
        <h2>About Us</h2>
        <p>
          At Playnet Interactive, we are passionate about merging the world of gaming with the power of branding and
          product development. Our company was founded with a unique vision: to blend our extensive experience in
          commercial product development with the cutting-edge expertise of seasoned gaming professionals. This fusion
          of skills allows us to create gaming experiences that are not only engaging and entertaining but also
          strategically aligned with our clients' business objectives.
          <GetInTouchButton className="about__contact-link" />
        </p>
      </SectionWithImages>
      <Section className="about__text-section">
        <h2>Our Mission</h2>
        <p>
          Our mission is to elevate gaming to new heights by integrating it seamlessly with product and brand
          strategies. We believe that gaming is not just about entertainment—it's a powerful tool that can drive
          engagement, boost brand awareness, and create lasting connections with users. Whether it's developing a
          captivating casual game, creating an immersive metaverse experience, or gamifying a brand to enhance user
          interaction, our goal is to turn your vision into a reality that resonates with your audience.
        </p>
      </Section>
      <SectionWithImages className="about__content">
        <h2>Our Team</h2>
        <p>
          Our team is composed of experts from diverse backgrounds, including product development, branding, and the
          gaming industry. With several years of experience, our professionals bring a deep understanding of what it
          takes to create products that captivate and engage. Whether you need innovative game design, art creation,
          animation, or custom development, our team is equipped to deliver high-quality solutions that meet and exceed
          expectations.
        </p>
      </SectionWithImages>
      <Section className="about__text-section">
        <h2>Why Choose Us?</h2>
        <p>
          Choosing Playnet Interactive means partnering with a company that understands both the creative and commercial
          sides of gaming. We offer full visibility throughout all stages of development, ensuring that every project is
          perfectly aligned with your overall business vision. Our iterative development process allows us to adapt and
          evolve your project in real-time, making sure that the final product not only meets your expectations but also
          enhances your brand's impact in the market. At Playnet Interactive, we're not just creating games; we're
          creating experiences that bring gaming and products together to a new level of entertainment.
        </p>
      </Section>
    </NavigationTemplate>
  );
};
