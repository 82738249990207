import { FC } from 'react';
import BgImg from '../../../../assets/menu-bg-dark.png';
import ClickPlay from '../../../../assets/click-play.jpg';
import { GetInTouchButton } from '../../../../components/shared/GetInTouchButton';

import './PlayClick.scss';

export const PlayClick: FC = () => {
  return (
    <div className="play_container">
      <div className="play__top-wrapper">
        <img src={BgImg} alt="background" className="play_container__background" />
        <GetInTouchButton className="play_container__link" />
      </div>
      <div className="play_container__content">
        <h2>Click & Play</h2>
        <h5>
          Thanks to our iterative development process, we provide full visibility across all development stages,
          ensuring perfect alignment with your overall business vision. Partner with us for a seamless, transparent, and
          collaborative experience.
        </h5>
      </div>
      <img src={ClickPlay} alt="Click Play" className="play_container__play-background" />
    </div>
  );
};
