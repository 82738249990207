import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseIcon } from '../../assets/SVG/close';
import { HamburgerIcon } from '../../assets/SVG/hamburger-menu';
import Logo from '../../assets/SVG/pn-logo.svg';
import { ROUTES } from '../../costants/routes';
import { joinStrings } from '../../utils/string';
import { Navigation } from '../Navigation';
import { IconButton } from '../shared/IconButton/IconButton';
import { Social } from '../Social';
import BGImg from '../../assets/header-bg3.jpg';
import NavBg from '../../assets/menu-bg.png';
import './Header.scss';
import { useDelayedMount } from '../../hooks/useDelayedMount';

interface Props {
  title: string;
  description: string;
  descriptionBold?: string;
}

export const Header: FC<Props> = ({ title, description, descriptionBold }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { shouldRender: shouldRenderMenu } = useDelayedMount(isOpen, 500, 1, 500);

  const toggleIsOpen = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <img src={NavBg} alt="nav-img" className={joinStrings(['header__nav-img', isOpen && 'header__nav-img--open'])} />
      <nav className={joinStrings(['header__nav', isOpen && 'header__nav--open'])}>
        <div className="header__logo-wrapper">
          <div className="header__left">
            <IconButton
              size="x-large"
              icon={shouldRenderMenu ? <CloseIcon /> : <HamburgerIcon />}
              aria-label="header-menu"
              onClick={toggleIsOpen}
              color="inherit"
            />
            <Navigation />
          </div>
          <Link to={ROUTES.HOME} className={joinStrings(['header__logo', isOpen && 'header__logo--open'])}>
            <img src={Logo} alt="logo" />
          </Link>
          <div className="header__title">
            <h1>{title}</h1>
            <p>
              {description} {descriptionBold && <strong>{descriptionBold}</strong>}
            </p>
          </div>
        </div>
        <div className="header__right">
          <Social />
        </div>
      </nav>

      <img src={BGImg} alt="logo" className="header__background" />
    </header>
  );
};
