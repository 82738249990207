import React, { FC } from 'react';

export const XIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50.999" viewBox="0 0 51 50.999">
    <g id="social-x" transform="translate(0.5 0.5)">
      <path
        id="Subtraction_3"
        data-name="Subtraction 3"
        d="M-93,47a24.845,24.845,0,0,1-9.731-1.965,24.916,24.916,0,0,1-7.947-5.358,24.916,24.916,0,0,1-5.358-7.947A24.845,24.845,0,0,1-118,22a24.843,24.843,0,0,1,1.965-9.731,24.915,24.915,0,0,1,5.358-7.946,24.916,24.916,0,0,1,7.947-5.358A24.845,24.845,0,0,1-93-3a24.843,24.843,0,0,1,9.731,1.965,24.916,24.916,0,0,1,7.946,5.358,24.916,24.916,0,0,1,5.358,7.946A24.843,24.843,0,0,1-68,22a24.845,24.845,0,0,1-1.965,9.731,24.916,24.916,0,0,1-5.358,7.947,24.915,24.915,0,0,1-7.946,5.358A24.843,24.843,0,0,1-93,47Zm-2.341-20.934h0l6.476,9.047h10.5L-89.393,19.7-79.279,8.887h-3.84l-7.938,8.491-6.079-8.491h-10.5l10.633,14.853L-107.64,35.114h3.84l8.458-9.047Zm11.569,6.266h-3.662l-14.793-20.664h3.662L-83.773,32.33Z"
        transform="translate(118 3)"
        fill="#ffc210"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);
