import React, { FC } from 'react';

export const WhatsIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50.999" viewBox="0 0 51 50.999">
    <g id="social-whats" transform="translate(0.5 0.5)">
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M-93,47a24.845,24.845,0,0,1-9.731-1.965,24.916,24.916,0,0,1-7.947-5.358,24.916,24.916,0,0,1-5.358-7.947A24.845,24.845,0,0,1-118,22a24.843,24.843,0,0,1,1.965-9.731,24.915,24.915,0,0,1,5.358-7.946,24.916,24.916,0,0,1,7.947-5.358A24.845,24.845,0,0,1-93-3a24.843,24.843,0,0,1,9.731,1.965,24.916,24.916,0,0,1,7.946,5.358,24.916,24.916,0,0,1,5.358,7.946A24.843,24.843,0,0,1-68,22a24.845,24.845,0,0,1-1.965,9.731,24.916,24.916,0,0,1-5.358,7.947,24.915,24.915,0,0,1-7.946,5.358A24.843,24.843,0,0,1-93,47Zm.41-41.028h0c-.166,0-.335,0-.5.008a14.95,14.95,0,0,0-10,4.313,14.921,14.921,0,0,0-4.495,9.916,15.009,15.009,0,0,0,1.668,7.7l-1.677,7.512a.479.479,0,0,0,.1.4.479.479,0,0,0,.373.179.48.48,0,0,0,.1-.011l7.511-1.678h.005A14.789,14.789,0,0,0-92.59,36c.237,0,.479-.006.717-.017a15.086,15.086,0,0,0,14.285-14.4A14.964,14.964,0,0,0-78.6,15.537a14.942,14.942,0,0,0-3.174-4.96,14.957,14.957,0,0,0-4.84-3.367,14.912,14.912,0,0,0-5.98-1.238Zm0,26.923a11.874,11.874,0,0,1-5.99-1.62c-.172-.1-.334-.2-.481-.3l-4.8,1.279,1.279-4.8a11.848,11.848,0,0,1-1.916-6.472A11.921,11.921,0,0,1-92.591,9.078,11.921,11.921,0,0,1-80.683,20.986,11.921,11.921,0,0,1-92.591,32.894Zm-4.648-19.156a1.054,1.054,0,0,0-.591.182,6.21,6.21,0,0,0-.95.779,3.571,3.571,0,0,0-1.038,2.9,11.957,11.957,0,0,0,3.607,7.013A12.029,12.029,0,0,0-89.2,28.216a3.522,3.522,0,0,0,.362.019A3.562,3.562,0,0,0-86.3,27.178a5.862,5.862,0,0,0,.773-.95A1.045,1.045,0,0,0-85.4,25.3a1.021,1.021,0,0,0-.651-.648l-2.367-.788a2.02,2.02,0,0,0-.634-.119.932.932,0,0,0-.7.279l-.634.629a.927.927,0,0,1-.66.274.944.944,0,0,1-.509-.149,16.079,16.079,0,0,1-2.622-2.206,16.153,16.153,0,0,1-2.206-2.622.932.932,0,0,1,.124-1.168l.629-.634a1.2,1.2,0,0,0,.161-1.334l-.789-2.367A1.036,1.036,0,0,0-97.239,13.738Z"
        transform="translate(118 3)"
        fill="#ffc210"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);
