import React, { FC, ReactNode } from 'react';
import { joinStrings } from '../../utils/string';
import { Footer } from '../Footer';
import { Header } from '../Header';

interface Props {
  title: string;
  description: string;
  className?: string;
  descriptionBold?: string;
  children: ReactNode;
}

export const NavigationTemplate: FC<Props> = ({ children, title, descriptionBold, description, className }) => {
  return (
    <div className={joinStrings(['navigation-template', className])}>
      <Header title={title} description={description} descriptionBold={descriptionBold} />
      {children}
      <Footer />
    </div>
  );
};
