import React, { FC } from 'react';
import footerBg from '../../assets/footer-bg.png';
import './Footer.scss';

export const Footer: FC = () => {
  return (
    <div className="footer">
      <img src={footerBg} alt="footer-img" />
      <div className="footer__title-wrapper">
        <h4 className="footer__title">Bringing Gaming and Products Together</h4>
        <h3 className="footer__sub-title">to a New Level of Entertainment</h3>
      </div>
      <div className="footer__contact">
        <p>
          CONTACT <strong>Playnet Interactive</strong>
        </p>
        <a href="mailto:hello@playnetinteractive.com" className="footer__email">
          hello@playnetinteractive.com
        </a>
        <p className="footer__license">©Playnet Interactive 2024</p>
      </div>
    </div>
  );
};
