import { FC } from 'react';
import './Home.scss';
import { WhoWeAre } from './components/WhoWeAre/WhoWeAre';
import { PlayClick } from './components/PlayClick/PlayClick';
import { NavigationTemplate } from '../../components/NavigationTemplate';

export const Home: FC = () => {
  return (
    <NavigationTemplate
      title="NEXT LEVEL GAME DEV"
      description="Welcome to"
      descriptionBold="Playnet Interactive"
      className="home"
    >
      <WhoWeAre />
      <PlayClick />
    </NavigationTemplate>
  );
};
